$(document).offOn("data-loaded", function () {

    var confidential = 0.000666;


    var percent = function (text) {
        if (text == null) return '';
        if (text == undefined) return '';
        return Math.round(parseFloat(text)) + '%';
    }

    var currency = function (text) {
         return '$' + Math.round(parseFloat(text));
    }

    var round1DP = function(num) {
        return Math.round(num * 10) / 10
    }

    var number = function(text) {
        var val = parseInt(text)
        if (val > 1000000) {
            return round1DP(val/1000000)+'M'
        }
        if (val > 1000) {
            return round1DP(val/1000)+'K'
        }
return ''+val;
    }

    let allConf = true;

    var cellValue = function(text, format, kind)  {
        text  = text.replace(/\s/g,'');

        if (text == '') {
               return (kind == 'line' ? NaN : confidential);
        }
        allConf = false
        switch (format) {
            case 'percent':
                return parseFloat(text) * 100;
        default:
            return text;
        }
    }

    var labeller = function(text, format, drop2sOrLess) {
        var label;
        switch (format) {
            case 'percent':
                if ((drop2sOrLess) && (Math.round(parseFloat(text)) <= 2)) {
                    label = '';
                } else {
                    label = percent(text);
                }
                break;
            case 'currency':
                label = currency(text)
                break;
            case 'number':
                label = number(text)
                break;
            default:
                label = text
        }
        return label;
    }

    $('table[data-chart]').each(function () {
        var that = $(this);
        // check we don't already have a matching chart generated.
        // if not extract data and labels from table and any settings
        // generate table based on data and settings
        var kind = that.attr('data-chart')
        var chartId, chartEl, title, labels
        var id = that.attr('id');
        if (id) {
            chartId = id + "-chart";
            chartEl = $('#' + chartId);
        } else {
            chartEl = that.next('.chartist')
        }
        if (chartEl.length < 1) {
            var options = that.dataObj().options || {};
            var format = options.format;
            var addClass = that.attr('data-chart-class');
            options.height = '400';
            if (addClass=='segment-stacked') {
                options.height = '288'
            }
            chartEl = $('<div class="chartist '+addClass+'" ' + (chartId ? 'id="' + chartId + '"' : '') + "/>");
            that.after(chartEl);
            // extract series labels from first row
            labels = that.find('tr:first:not(.dont-chart) th:not(.dont-chart), tr:first:not(.dont-chart) td:not(.dont-chart)').map(function (i, el) {
                return $(el).text();
            });
            [title, ...labels] = labels;
            var cells = that.find('tr:not(:first,.dont-chart)').map(function (i, row) {
                return [$(row).find('th:not(.dont-chart), td:not(.dont-chart)').map(function (j, cell) {
                    return j == 0 ? $(cell).text() : cellValue($(cell).text(),format, kind);
                }).toArray()];
            }).toArray();

            var series = cells.map(function (row, i) {
                var first, rest;
                [first, ...rest] = row
                var result = {name: first, data: rest}
                return result
            });
            var data = {
                labels: labels,
                series: series
            }
            var drop2sOrLess = false
            if ((options.horizontalBars) && (format == 'percent')) {
                drop2sOrLess = true;
                if (!options.axisX) options.axisX = {}
                options.axisX.type= Chartist.FixedScaleAxis;
                    options.axisX.ticks= [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
            }
            if ((format) && !(options.horizontalBars)) {

                if (!options.axisY) options.axisY = {}
                if (format == 'percent') {
                    options.axisY.low = 0;
                    options.axisY.high = 100;
                    options.axisY.type= Chartist.FixedScaleAxis;
                    options.axisY.ticks= [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
                }
                if ((format == 'number') || (format == 'currency')) {
                    options.axisY.low = 0;
                    if (allConf) {
                        options.axisY.high = 100;
                    }
                }
                options.axisY.labelInterpolationFnc = function(text) {
                    return labeller(text,format);
                }
            }
            options.seriesBarDistance = 50;
            options.chartPadding = {
                top: 30,
                right: 15,
                bottom: 30,
                left: 10
            }
            if (!options.axisY) options.axisY = {};

            if (options.axisY.offset) {
                options.axisY.offset = parseInt(options.axisY.offset)  //string value seems to cause problems
            } else {
                options.axisY.offset = 60;
            }
            options.plugins = [
                Chartist.plugins.ctBarLabels({
                    labelInterpolationFnc: function (text) {
                        if (text == confidential) {
                            return options.horizontalBars ? '':  'C';
                        }
                        return labeller(text,format,drop2sOrLess);
                    },
                    position: {
                        y: function (data) {
                            var y = data.y2
                            return options.horizontalBars ? y - 30 : y - 10;
                        }
                    }
                })
            ];
            if (series.length > 1) {
                options.plugins.push(Chartist.plugins.legend({
                    position: 'bottom'
                }))
            }
            that.hide();
            var chartFn = kind =='line' ?  Chartist.Line :  Chartist.Bar
             new chartFn(chartEl[0], data, options);
            setTimeout(function () {
                chartEl.prepend('<div class="ct-title">' + title + '</div>')
            }, 1)
        }
    })
});